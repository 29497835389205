import Icons from "./assets/images/icons";

const { faInbox, faUpload, faLock, faBomb } = Icons;

const features = [
  {
    id: 1,
    feature: "Protect your data",
    description:
      "With Artificial intelligence and human negligence taking over it is very important to protect your data.",
    icon: faInbox,
  },
  {
    id: 2,
    feature: "Lock your data",
    description:
      "Make sure that you lock your data in a private device out of reach of cloud/internet.",
    icon: faLock,
  },
  {
    id: 3,
    feature: "Remove data",
    description:
      "Remove data about you that already exists on the internet. Leave no digital footprint.",
    icon: faBomb,
  },
  {
    id: 4,
    feature: "Stay out of Radar",
    description:
      "Make sure to take steps to stay out of the Radar. Take steps to reduce digital footprint.",
    icon: faUpload
  },
];

export default features;
