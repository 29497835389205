const links = [
  {
    name: "Home",
    url: "#home",
  },
  {
    name: "features",
    url: "#features",
  },
  {
    name: "about",
    url: "#about",
  },
  {
    name: "Order Procedure",
    url: "#orderprocedures",
  },
  {
    name: "services",
    url: "#services",
  }
];

export default links;
