import Icons from "./assets/images/icons";
import images from "./assets/images/images";

const { faFloppyDisk, faFile, faZap, faInbox, faMobile, faPlug } = Icons;
const { Image1, Image2, Image3, Image4, Image5, Image6 } = images;

const services = [
  {
    id: 1,
    service: "USB GPT Jammer",
    description:
      "This USB device makes sure that GPT is not spying on you and makes sure your location, cookies and history is not read on GPT site.",
    image: Image1,
    icon: faFloppyDisk,
    link: 'https://commerce.coinbase.com/checkout/ce70125f-dc25-4d54-a38f-15561905b5fb'
  },
  {
    id: 2,
    service: "Car GPT Jammer",
    description:
      "This device connects to the OBD port of your car and makes sure that GPT is not spying on you by jamming nearby sensors.",
    image: Image2,
    icon: faFile,
    link: 'https://commerce.coinbase.com/checkout/b826ebba-a11e-4152-89c3-e7214c7fab63'
  },
  {
    id: 3,
    service: "CCTV Jammer",
    description:
      "This device attaches to the suit of a person and it jams any cctv camera spying on them. (Range=800 metre)",
    image: Image3,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/3d3df17d-5f22-4213-84ab-4eab2a30a74a'
  },
  {
    id: 4,
    service: "Suvivalist kit",
    description:
      "This is an extensive survivalist kit that comes in handy in extreme situations. The kit includes CCTV Jammer, Car GPT Jammer, Electro-P1 suit apart from other small mechanical devices.",
    image: Image4,
    icon: faInbox,
    link: 'https://commerce.coinbase.com/checkout/1654aba1-1596-4e65-87f2-369b2fc4dd30'
  },
  {
    id: 5,
    service: "Signal Jammer + Satelite phone",
    description:
      "This kit consists of a signal jammer(Range=500 metres) + a satelite phone working outside the cloud/internet.",
    image: Image5,
    icon: faMobile,
    link: 'https://commerce.coinbase.com/checkout/f77f0736-ad6c-4b14-bc06-93073b1c4686'
  },
  {
    id: 6,
    service: "Electro-P1 suit (BETA)",
    description:
      "This is a electo-P1 suit manufactured by StopGPT. The person wearing the suit is invisible to CCTV cameras, GPS, tear gas and radar.",
    image: Image6,
    icon: faPlug,
    link: 'https://commerce.coinbase.com/checkout/3a91cf6f-9d87-4eb8-b786-155dbf064dfd'
  }
];

export default services;
