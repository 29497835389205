import React from "react";
import styled from "styled-components";
import Image from "../assets/images/about-bcg.jpeg";

const About = () => {
  return (
    <Wrapper>
      <section className='section-center clearfix' id='about'>
        <article className='about-img'>
          <div className='about-picture-container'>
            <img src={Image} alt='kettle' className='about-picture' />
          </div>
        </article>
        <article className='about-info'>
          <div className='section-title'>
            <h3>Stop GPT</h3>
          </div>
          <p className='about-text'>
          If we do not prepare now, we may face a future where AI runs free and dominates humans in society. The AI revolution is indeed underway. 
          </p>
          <p className='about-text'>
          To ensure you are prepared to make it through the times ahead, we’ve created a handy survival guide for you.
          </p>
          <p className='about-text'>
          All our products/services will be delivered anonymously. Anonymity is the heart of this website. We collect no data and use only crypto payments.
          </p>
          <a href='#orderprocedures' className='btn'>
            Learn More
          </a>
        </article>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .about-img,
  .about-info {
    padding: 2rem 0;
  }

  .about-picture-container {
    background-color: var(--clr-primary);
    border: 0.5rem solid var(--clr-primary);
    max-width: 30rem;
    overflow: hidden;
  }

  .about-picture {
    transition: var(--transition);
  }

  .about-picture-container:hover .about-picture {
    opacity: 0.5;
    transform: scale(1.2);
  }

  .about-text {
    max-width: 26rem;
    color: var(--clr-grey-5);
  }

  @media screen and (min-width: 992px) {
    .about-img,
    .about-info {
      float: left;
      width: 50%;
    }
    .about-info {
      padding-left: 2rem;
    }
  }
`;

export default About;
