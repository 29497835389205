import Image1 from "../images/service-1.jpg";
import Image2 from "../images/service-2.jpg";
import Image3 from "../images/service-3.jpg";
import Image4 from "../images/service-4.jpg";
import Image5 from "../images/service-5.jpg";
import Image6 from "../images/service-6.jpg";

const images = { Image1, Image2, Image3, Image4, Image5, Image6 };

export default images;
